<template>
  <div>
    <modal name="modal-addpayroll" @before-open="beforeOpen" with="auto" height="auto">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate"
        ng-style="{&#39;z-index&#39;: 1050 + $topModalIndex*10, display: &#39;block&#39;}" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel" style="text-align: center">
                    <span style="color: #334152">Process New Payroll</span>
                  </h5>
                  <button v-on:click="this.closeModal" aria-label="Close" class="box-title close"
                    type="button"></button>
                </div>

                <div class="modal-body">
                  <div class="row">
                    <div class="col-12 text-center">
                      <div class="pt-user-name">
                        <div>
                          <img src="/assets/img/lantern.png" style="width: 256px; display: inline-block; padding-left: 50px;" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center text-success bold ng-binding" style="font-size: 22px">
                    {{ getTimeRunPayrool(payRunPeriod, endPayPeriod) }}
                  </div>
                  <div class="text-center text-danger bold ng-binding" v-if="isPaymentDate">
                    Payment Date cannot be Smaller than Pay Period Ending date.
                  </div>
                  <div class="text-center text-danger bold ng-binding" v-if="isCurrentDateMatched">
                    Pay Period Ending cannot be greater than current date.
                  </div>
                  <div class="row text-left mt-3">
                    <div class="col-sm-4">
                      <label for="">Payroll Period</label>
                      <select v-model="payRunPeriod" name="state" @change="onChangedpayPeriod($event)" class="
                          form-control
                          ng-pristine ng-untouched ng-valid ng-not-empty
                        ">
                        <option label="Weekly" value="Weekly">Weekly</option>
                        <option label="Fortnightly" value="Fortnightly">
                          Fortnightly
                        </option>
                        <option label="Monthly" value="Monthly">Monthly</option>
                        <!-- <option label="Quarterly" value="string:q">Quarterly</option> -->
                      </select>
                    </div>
                    <div class="col-sm-4">
                      <label for="">Pay Period Ending</label>
                      <date-picker v-model="endPayPeriod" @close="closeDatePicker()" type="date" value-type="YYYY-MM-DD"
                        format="DD/MM/YYYY" :disabled-date="disabledBefore" class="
                          date-picker
                          ng-pristine
                          ng-untouched
                          ng-valid
                          ng-isolate-scope
                          ng-not-empty
                        "></date-picker>
                    </div>
                    <div class="col-sm-4">
                      <label for="">Payment Date</label>
                      <date-picker v-model="paymentDate" type="date" value-type="YYYY-MM-DD" format="DD/MM/YYYY"
                        @close="closeDatePickerPaymentDate()" :disabled-date="disabledBefore" class="
                          date-picker
                          ng-pristine
                          ng-untouched
                          ng-valid
                          ng-isolate-scope
                          ng-not-empty
                        "></date-picker>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <div class="row text-left" style="margin-top: 15px">
                    <v-app class="text-left" style="margin-left: 20px">
                      <v-switch v-model="isShowCoppy" inset style="margin-top: 0px"></v-switch>
                    </v-app>
                    <div class="text-right" style="margin-top: 3px">
                      <label for="leaveLoading">Copy pay items from previous pay<i
                          class="icon-feather-help-circle text-primary"
                          style="vertical-align: middle; margin-left: 5px"></i></label>
                    </div>
                  </div>
                  <div class="col-lg-4 text-right" style="padding-left: 0px; padding-right: 0px">
                    <!-- <button v-on:click="this.closeModal" class="btn btn-light" type="button" style="margin-right: 5px;"> Close</button> -->
                    <button v-on:click="addNewPayRunModal()" class="btn btn-primary" type="button"
                      :disabled="isCurrentDateMatched || isPaymentDate" ng-disabled="loading">
                      Let's Start!
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="modal-Learn-More" @before-open="beforeOpenLearnMore" with="auto" height="auto" style="z-index: 2000">
      <div uib-modal-window="modal-window" class="modal fade ng-scope ng-isolate-scope show" role="dialog"
        aria-labelledby="modal-title" aria-describedby="modal-body" size="md" index="0" animate="animate" tabindex="-1"
        uib-modal-animation-class="fade" modal-in-class="show" modal-animation="true"
        style="z-index: 1050; display: block; overflow: auto">
        <div class="modal-dialog modal-md">
          <div class="modal-content" uib-modal-transclude="">
            <div class="
                modal-content
                text-center
                onboarding-modal
                modal-centered
                ng-scope
              ">
              <div class="modal-content" style="height: max-content">
                <!-- <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" style="text-align:center;">
                                    <span class="ng-binding">Copy pay items from previous pay</span>
                                </h5>
                                <div class="ng-confirm-content-pane" style="height: 63px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                    <div class="ng-confirm-content" id="ng-confirm-box418771">
                                        <div class="ng-scope">We know from pay run to pay run your employee's pay items and rates more or less stay the same. Rather than re-entering these details, copy pay items from previous pay copies the pay item details for the selected employee(s) from the last pay run they were included in.
                                            Copied details include: 
                                            • Earnings & leave items 
                                            • Allowance items 
                                            • Deduction items 
                                            • Superannuation items 
                                            • Rates 
                                            • Hours 
                                            • Quantities
                                            Once copied you can edit any of these values. For example, if your employee is casual you can adjust their hours. You can also add or remove pay items as required.
                                        </div>
                                    </div>
                                </div>
                                <button v-on:click="$modal.hide('modal-Learn-More')" aria-label="Close" class="box-title close" type="button"></button>
                            </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <div
      v-if="showFailedValidation" 
      class="ng-confirm ng-confirm-white ng-confirm-type-default">
      <div class="ng-confirm ng-confirm-white ng-confirm-type-default">
            <div class="ng-confirm-bg" style="transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);"></div>
            <div class="ng-confirm-scrollpane">
                <div class="ng-bs3-container container">
                    <div class="ng-bs3-row row justify-content-md-center justify-content-sm-center justify-content-xs-center justify-content-lg-center">
                        <div class="ng-confirm-box-p col-md-4 col-md-offset-4 col-sm-6 col-sm-offset-3 col-xs-10 col-xs-offset-1">
                            <div class="ng-confirm-box ng-confirm-hilight-shake ng-confirm-type-animated" role="dialog" aria-labelledby="ng-confirm-box418771" tabindex="-1" style="margin-top: 76.3595px; margin-bottom: 0px; transition-duration: 0.4s; transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);">
                                <div class="ng-confirm-closeIcon ng-confirm-el-hide"><span>×</span></div>
                                <div class="ng-confirm-title-c">
                                    <span class="ng-confirm-icon-c ng-confirm-el-hide"><i></i></span>
                                    <span class="ng-confirm-title">Confirm pay run</span>
                                </div>
                                <div class="ng-confirm-content-pane" style="height: auto;
                                    transition-duration: 0.4s;
                                    transition-timing-function: cubic-bezier(0.36, 0.55, 0.19, 1);
                                    overflow-y: auto;
                                    max-height: 500px;">
                                    <div class="ng-confirm-content" id="ng-confirm-box418771">
                                        <div class="ng-scope">The payroll will continue processing but exclude these employees</div>
                                        <ul class="employee-list">
                                          <li v-for="(employee, index) in failedEmployees" 
                                              :key="index" 
                                              class="employee-item">
                                            <i class="icon-user"></i>
                                            {{ employee.firstName }} {{ employee.lastName }} - 
                                            <span class="text-muted">{{ employee.email }}</span>
                                          </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="ng-confirm-buttons">
                                    <button v-on:click="handleContinue(payrollResponse)" type="button" class="btn btn-primary">
                                        <span class="ng-confirm-btn-text">Continue</span>
                                    </button>
                                    <button v-on:click="hideFailedValidationPay()" type="button" class="btn btn-default">
                                        <span class="ng-confirm-btn-text">Cancel</span>
                                    </button>
                                </div>
                                <div class="ng-confirm-clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  </div>
</template>

<script>
import {
  ADD_NEW_RUNPAYROLL,
  DETAIL_RUNPAYROLL,
  GET_ITEMS_FROM_PREVIOUS_PAYROLL,
} from "@/store/actionsType";
import * as Session from "@/utils/SessionStore";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import * as Utils from "@/utils/Utils";

export default {
  name: "AddPayRollModal",
  props: [],
  components: {
    DatePicker,
  },
  data() {
    return {
      showFailedValidation: false,
      failedEmployees: [],
      payRunPeriod: "",
      startPayPeriod: "",
      endPayPeriod: "",
      paymentDate: "",
      endPayWeekly: "",
      endPayFortnightly: "",
      endPayMonthly: "",
      isShowCoppy: false,
      detailRun: "",
      currentDate:new Date(),
      isCurrentDateMatched:Boolean,
      isPaymentDate:false,
    };
  },

  methods: {
    handleContinue (response) {
      // console.log(response);
      if (this.isShowCoppy) {
        this.getDetailPayroll(response);
      } else {
        Session.set("runpayroll", response);
        this.$router.push({
          path: "/runpayroll",
        });
      }
    },
    disabledBefore(date) {
      var StartDate = '';
      var payrollStartDate = Session.get("payrollStartDate");
      var customStartDate = new Date("2021-06-30");
       if (new Date(payrollStartDate) < customStartDate) {
          StartDate = new Date(payrollStartDate);
       } else {
          StartDate = customStartDate;
       }
      var EndDate = new Date();
      return date < StartDate || date > EndDate;
    },
    beforeOpen(event) {
      this.endPayWeekly = event.params.endPayWeekly;
      this.endPayFortnightly = event.params.endPayFortnightly;
      this.endPayMonthly = event.params.endPayMonthly;

      this.lastIdPayrollWeekly = event.params.lastIdPayrollWeekly;
      this.lastIdPayrollFortnightly = event.params.lastIdPayrollFortnightly;
      this.lastIdPayrollMonthly = event.params.lastIdPayrollMonthly;

      this.payRunPeriod = Session.get("payPeriod");

      this.endPayPeriod = this.getPayRollStartDate();
      this.paymentDate = this.getPaymentDate(this.getPayRollStartDate());
     this.isPaymentDate = false;
      this.getEndPayPeriod(this.payRunPeriod);
    },
    showLearnMore() {
      // this.$modal.show('modal-Learn-More', {
      // });
    },
    closeModal() {
      this.$modal.hide("modal-addpayroll");
    },
    onChangedpayPeriod(event) {
      this.payRunPeriod = event.target.value;
      this.getEndPayPeriod(this.payRunPeriod);
    },
    showFailedValidationPay(employeesNotActive) {
        this.failedEmployees = employeesNotActive || [];
        this.showFailedValidation = true;
    },
    hideFailedValidationPay() {
        this.showFailedValidation = false;
    },
    closeDatePicker() {
      this.paymentDate = this.getPaymentDate(this.endPayPeriod);
      let currentDate =  this.formatDate(this.currentDate)
      if(this.endPayPeriod > currentDate) {
        this.isCurrentDateMatched = true;
      }
      if (this.endPayPeriod <= currentDate) {
        this.isCurrentDateMatched = false;
      }

       if(this.paymentDate > currentDate) {
       this.paymentDate = currentDate
      }

     if(this.endPayPeriod <= this.paymentDate) {
        this.isPaymentDate = false;
      }
     
    },
    closeDatePickerPaymentDate() {
      if(this.endPayPeriod <= this.paymentDate) {
        this.isPaymentDate = false;
      }
      if (this.endPayPeriod > this.paymentDate) { 
         this.isPaymentDate = true;
      }
    },
    getEndPayPeriod(payRunPeriod) {
      var endPayPeriod = "";
      switch (payRunPeriod) {
        case "Weekly":
          if (this.endPayWeekly != "") {
            endPayPeriod = new Date(this.endPayWeekly);
            endPayPeriod.setDate(endPayPeriod.getDate() + 7);
          }
          break;
        case "Fortnightly":
          if (this.endPayFortnightly != "") {
            endPayPeriod = new Date(this.endPayFortnightly);
            endPayPeriod.setDate(endPayPeriod.getDate() + 14);
          }
          break;
        case "Monthly":
          if (this.endPayMonthly != "") {
            endPayPeriod = new Date(this.endPayMonthly);
            endPayPeriod.setDate(endPayPeriod.getDate() + 30);
          }
          break;
        default:
          break;
      }
      if (endPayPeriod != "") {
        this.endPayPeriod = this.formatDate(endPayPeriod);
      } else {
        this.endPayPeriod = this.getPayRollStartDate();
      }

      var currentDate = this.formatDate(this.currentDate);
       if (this.endPayPeriod > currentDate) {
        this.isCurrentDateMatched = true;
       }else {
         this.isCurrentDateMatched = false;
       }
      this.paymentDate = this.getPaymentDate(this.endPayPeriod);
    },

    getTimeRunPayrool(payRunPeriod, endPayPeriod) {
      var startDay = new Date(endPayPeriod);
      switch (payRunPeriod) {
        case "Weekly":
          startDay.setDate(startDay.getDate() - 6);
          break;
        case "Fortnightly":
          startDay.setDate(startDay.getDate() - 13);
          break;
        case "Monthly":
          var mounth = startDay.getMonth() + 1;
          var year = startDay.getFullYear();
          var numberDay = new Date(year, mounth, 0).getDate() - 1;
          
          startDay.setDate(startDay.getDate() - numberDay);
          break;
        case "Quarterly":
          startDay.setMonth(startDay.getMonth() - 3);
          break;
        default:
          break;
      }
      this.startPayPeriod = this.formatDate(startDay);
      return (
        this.formatDateToString(startDay) +
        " - " +
        this.formatDateToString(endPayPeriod)
      );
    },

    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    formatDateToString(inputDate) {
      var m_names = new Array(
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      );
      var date = new Date(inputDate);
      var day = date.getDate();
      var month = date.getMonth();
      var year = date.getFullYear();
      var dateShow = day + " " + m_names[month] + " " + year;
      return dateShow;
    },
    checkDate(startPayPeriod, paymentDate) {
      var d1 = new Date(paymentDate);
      var d2 = new Date(startPayPeriod);
      if (d1.getTime() <= d2.getTime()) {
        return false;
      }
      return true;
    },
    getPayRollStartDate() {
      var payrollStartDate = Session.get("payrollStartDate");
      var date = new Date(payrollStartDate);
      switch (this.payRunPeriod) {
        case "Weekly":
          date.setDate(date.getDate() + 6);
          break;
        case "Fortnightly":
          date.setDate(date.getDate() + 13);
          break;
        case "Monthly":
          var mounthMonthly = date.getMonth() + 1;
          var yeaMonthly = date.getFullYear();
          var numberDay = new Date(yeaMonthly, mounthMonthly, 0).getDate() - 1;
          
          date.setDate(date.getDate() + numberDay);
          break;
        case "Quarterly":
          date.setMonth(date.getMonth() + 3);
          break;
        default:
          break;
      }

      var day = date.getDate();
      var mount = date.getMonth() + 1;
      var year = date.getFullYear();

      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (mount.toString().length == 1) {
        mount = "0" + mount;
      }
      return year + "-" + mount + "-" + day;
    },
    getPaymentDate(inputDate) {
      var date = new Date(inputDate);
      date.setDate(date.getDate() + 3);
      var day = date.getDate();
      var mount = date.getMonth() + 1;
      var year = date.getFullYear();
      if (day.toString().length == 1) {
        day = "0" + day;
      }
      if (mount.toString().length == 1) {
        mount = "0" + mount;
      }
      
      return year + "-" + mount + "-" + day;
    },
    // Request API
    getDetailPayroll(responseNewPayRun) {
      var payrollId = "";
      switch (this.payRunPeriod) {
        case "Weekly":
          payrollId = this.lastIdPayrollWeekly;
          break;
        case "Fortnightly":
          payrollId = this.lastIdPayrollFortnightly;
          break;
        case "Monthly":
          payrollId = this.lastIdPayrollMonthly;
          break;
        default:
          break;
      }

      if (payrollId !== "") {
        this.$store
          .dispatch(DETAIL_RUNPAYROLL, payrollId)
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            var listRunPayrollHistory = response.payrollDto.adjustReview;
            var listRunPayroll = responseNewPayRun.adjustReview;
            for (var i = 0; i < listRunPayrollHistory.length; i++) {
              for (var j = 0; j < listRunPayroll.length; j++) {
                if (
                  listRunPayroll[j].employeeData.id ===
                  listRunPayrollHistory[i].employeeData.id
                  // &&
                  //listRunPayroll[j].id === listRunPayrollHistory[i].id
                ) {
                  listRunPayroll[j] = listRunPayrollHistory[i];
                  break;
                }
              }
            }
            for (var k = 0; k < listRunPayroll.length; k++) {
              if (listRunPayroll[k].id === 0) {
                listRunPayroll[k].activePayroll = "INACTIVE";
              }
            }
            
            responseNewPayRun.adjustReview = listRunPayroll;
            Session.set("runpayroll", responseNewPayRun);
            this.$router.push({
              path: "/runpayroll",
            });
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      } else {
        var CompanyId = Session.get("currenCompanyId");
        this.GetIdOfPreviousPayroll(
          this.payRunPeriod,
          CompanyId,
          responseNewPayRun
        );
      }
    },
    GetIdOfPreviousPayroll(payRunPeriod, CompanyId, responseNewPayRun) {
      this.$root.$refs.AppCyrus.start();
      var payrollID;
      var dataRequest = {
        companyId: CompanyId,
        payRunPeriod: payRunPeriod,
        startPayPeriod: this.startPayPeriod,
        endPayPeriod: this.endPayPeriod,
        paymentDate: this.paymentDate,
      };

      this.$store
        .dispatch(GET_ITEMS_FROM_PREVIOUS_PAYROLL, dataRequest)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          payrollID = response;
          if (payrollID > 0) {
            this.$store
              .dispatch(DETAIL_RUNPAYROLL, payrollID)
              .then((response) => {
                this.$root.$refs.AppCyrus.finish();
                var listRunPayrollHistory = response.payrollDto.adjustReview;
                var listRunPayroll = responseNewPayRun.adjustReview;
                for (var i = 0; i < listRunPayrollHistory.length; i++) {
                  for (var j = 0; j < listRunPayroll.length; j++) {
                    if (
                      listRunPayroll[j].employeeData.id ===
                      listRunPayrollHistory[i].employeeData.id
                    ) {
                      listRunPayroll[j] = listRunPayrollHistory[i];
                      break;
                    }
                  }
                }

                for (var k = 0; k < listRunPayroll.length; k++) {
                  if (listRunPayroll[k].id === 0) {
                    listRunPayroll[k].activePayroll = "INACTIVE";
                  }
                }
                responseNewPayRun.adjustReview = listRunPayroll;
                Session.set("runpayroll", responseNewPayRun);
                this.$router.push({
                  path: "/runpayroll",
                });
              })
              .catch(() => {
                this.$root.$refs.AppCyrus.fail();
              });
          } else {
            Session.set("runpayroll", responseNewPayRun);
            this.$router.push({
              path: "/runpayroll",
            });
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
        });
    },
    addNewPayRunModal() {
      if (this.checkDate(this.startPayPeriod, this.paymentDate)) {
        this.$root.$refs.AppCyrus.start();
        var dataRequest = {
          companyId: Session.get("currenCompanyId"),
          payRunPeriod: this.payRunPeriod,
          startPayPeriod: this.startPayPeriod,
          endPayPeriod: this.endPayPeriod,
          paymentDate: this.paymentDate,
        };
        this.$store
          .dispatch(ADD_NEW_RUNPAYROLL, dataRequest)
          .then((response) => {
            this.$root.$refs.AppCyrus.finish();
            if (response.listemployeesNotActive && response.listemployeesNotActive.length > 0) {
              this.showFailedValidationPay(response.listemployeesNotActive);
              this.payrollResponse = response;
            }else{
              if(this.isShowCoppy){
                this.getDetailPayroll(response)
              }else{
                Session.set("runpayroll" , response);
                this.$router.push({
                  path:"/runpayroll",
                });
              }
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
          });
      } else {
        Utils.toastError("Payment date cannot be earlier than payroll start date.")
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
$default-color: #555;
$primary-color: #1284e7;
$input-color: #495057;
$input-border-color: transparent;
$input-hover-border-color: transparent;
@import "~vue2-datepicker/scss/index.scss"; //https://github.com/mengxiong10/vue2-datepicker/blob/master/src/style/index.scss

.date-picker {
  padding: 0px 0px 0px 0px !important;
  width: 100%;
  height: auto;
}
</style><style lang="scss">
.mx-datepicker-popup {
  z-index: 99999 !important;
}
.employee-list {
  list-style: none;
  padding: 0;
  margin-top: 10px;
}

.employee-item {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.employee-item i {
  color: #007bff;
}

.employee-item:hover {
  background: #e9ecef;
  transform: scale(1.02);
}

.text-muted {
  color: #6c757d;
}
</style>
